.grievancefeed-container {
  height: 100vh;
  width: 100%;
}
.Grievancecard {
  height: auto;
  width: 80%;
  margin-top: 10px;
  background-color: aliceblue;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.Navspace {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 120px;
}

.Addcontent {
  display: flex;
  height: auto;
  width: auto;
  background-color: #90b5f1;
  padding: 10px 10px 10px 10px;
  font-weight: bold;
  font-size: large;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.Addimage {
  display: flex;
  width: 30px;
}

.Grievancefeed {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.Grievancecontent {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.Grievanceimage {
  width: 300px;
  height: 200px;
}

.userName {
  font-size: large;
  font-weight: bold;
}

.city {
  font-size: large;
}

.time {
  font-size: large;
}

.Headline {
  font-size: medium;
  font-weight: bold;
}

.Description {
  font-size: medium;
}

.likeicon {
  height: 25px;
}

.reporticon {
  width: 30px;
  padding-bottom: 15px;
}

.modaltitle {
  font-size: 25px;
}

.modalbody {
  font-size: larger;
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .Grievancecard {
    height: auto;
    width: 800px;
    margin-top: 10px;
    background-color: aliceblue;
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .Grievimage {
    width: 300px;
  }
}

@media screen and (min-width: 320px) and (max-width: 769px) {
  .Grievancecard {
    height: auto;
    width: 300px;
    margin-top: 10px;
    background-color: aliceblue;
    display: flex;
    flex-wrap: wrap;
  }

  .Navspace {
    display: flex;
    justify-items: flex-end;
    padding-right: 10px;
    padding-top: 100px;
  }

  .Addcontent {
    display: flex;
    height: auto;
    width: auto;
    background-color: rgb(121, 198, 235);
    padding: 5px 5px 5px 5px;
    font-weight: bold;
    font-size: small;
    gap: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  .Addimage {
    display: flex;
    width: 20px;
  }

  .Grievancefeed {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .Grievancecontent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .userName {
    font-size: medium;
    font-weight: bold;
  }

  .city {
    font-size: small;
  }

  .time {
    font-size: small;
  }

  .Headline {
    font-size: smaller;
    font-weight: bold;
  }

  .Description {
    font-size: smaller;
  }

  .likeicon {
    height: 20px;
  }

  .reporticon {
    width: 25px;
    padding-top: 2px;
  }

  .modal {
    /* width: 320px; */
  }

  .modaltitle {
    font-size: 25px;
  }

  .modalbody {
    font-size: larger;
    font-weight: bold;
  }

  .modalHeadline {
    font-size: medium;
    width: 250px;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
  }

  .modalcategory {
    font-size: medium;
    width: 250px;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
  }

  .modaldescription {
    font-size: medium;
    width: 250px;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
  }

  .modalfile {
    /* padding-right: 10px; */
  }

  .modalplace {
    font-size: medium;
    width: 250px;
    padding: 5px 5px 5px 5px;
    border-radius: 2px;
  }

  .modalButton {
    justify-content: center;
  }
}
