.scrollable-section {
  width: 100%; 
  overflow-y: scroll; 
  scroll-behavior: smooth;
}

.landingpage-section-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
.button-Landingpage {
  height: 50px;
  width: 300px;
  font-size: larger;
  font-weight: bold;
}

.News-image {
  height: 300px;
  width: 500px;
  
}
.landing-page-section2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}
.News-Section {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: fit-content;
  padding: 10px 10px;
 
}

.landingpage-heading {
  font-size: 30px;
  font-weight: 700;
  animation: slidein 2.5s ease-in-out;
  overflow: hidden;
}
@keyframes slidein {
  from {
    margin-right: 0%;
    width: 300%;
  }

  50% {
    font-size: 300%;
    margin-right: 75%;
    width: 150%;
  }

  to {
    margin-right: 100%;
    width: 100%;
  }
}

 
.landingpage-heading::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.store-image {
  width: 150px;
  height: 150px;
}
.landingpage-mobile {
  width: 400px;
  height: 500px;
}


.landingpage-maintext {
  animation: fadeIn 3s;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .landingpage-section-1 {
    margin-top: 10px;
  }
  .landing-page-section2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vh;

  }
  .News-Section {
    display: flex;
    flex-direction: row;
    width: 90%;
    /* height: 250px; */
     padding: 10px 10px;
    flex-shrink: 0;
    overflow-x: hidden;
  }
  .News-image {
    width: 300px;
    height: 200px;
  }

  .landingpage-heading{
    animation: none !important;
  }
  .landingpage-maintext {
    animation: fadeIn 3s;
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .landingpage-mobile {
    width: 400px;
    height: 500px;
    animation: rotateRightToLeft 2s ease-in-out;
    /* background-color: aqua; */
  }
  
  @keyframes rotateRightToLeft {
    0% {
      transform: translateX(200%)
    }
    100% {
      transform: translateX(0);
    }
  }
  
}

@media screen and (min-width: 320px) and (max-width: 769px) {
  .landingpage-section-1 {
    margin-top: 10px;
  }
  .landingpage-mobile {
    width: 300px;
    height: 400px;
  }
  
  .landingpage-heading {
    font-size: 20px;
    font-weight: 400;
  }
  .button-Landingpage {
    height: 30px;
    width: 200px;
    font-size: smaller;
    font-weight: bold;
  }

  .landingpage-heading {
    font-size: small;
    font-weight: bold;
    animation: none;
  }

  .landingpage-maintext {
    font-size: x-small;
    font-weight: normal;
    padding: 5px;

  }
  

  .store-image {
    width: 100px;
  }

  .News-Section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .News-image {
    width: 250px;
    height: 200px;
  }

  .News-Headline {
    font-size: smaller;
    font-weight: bold;
    line-height: normal;
    padding: 5px 20px;
  }

  .News-Description {
    font-size: x-small;
    font-weight: normal;
    padding: 5px 20px;
  }
  .landingpage-mobile {
  
    animation: rotateRightToLeft 2s ease-in-out;
    /* background-color: aqua; */
  }
  
  @keyframes rotateRightToLeft {
    0% {
      transform: translateX(200%)
    }
    100% {
      transform: translateX(0);
    }
  }
}
