
.Signup-component {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Signup-button {
    margin-top: 5px;
    height: 30px;
    width: 100px;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: bold
    
}
  
  .input-box:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  .alert-text {
    font-size: smaller;
  }
  

@media screen and (min-width: 320px) and (max-width: 769px) {

    .Signup-component {
        margin-top: 0px;
        margin-bottom: 0px;
        gap: 15px;
       
    }
    .Signup-button {
        margin-top: 0px;
        height: 25px;
        width: 60px;
        align-items: center;
        justify-content: center;
        font-size: smaller;
        font-weight: bold
        
    }

    .alert-text {
        font-size: smaller;
       
      }

      .Visibility {
        width: 15px;
       
      }
}