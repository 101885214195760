.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 200;
  justify-content: space-between;
  align-content: center;
  background-color: #488efe;
  padding: 5px;
}

.Language {
  display: flex;
  gap: 20px;
  align-items: center;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
  top: 0;
}
#\:1\.container {
  display: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.goog-te-combo {
  position: absolute !important;
  height: 35px;
  width: 200px;
}

.image-container img {
  transition: transform 0.9s ease;
}

.image-container img.zoom-out:hover {
  transform: scale(1.3);
}
.image-container p.zoom-out:hover {
  transform: scale(1.1);
}

@keyframes shake {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
.bell-icon:hover {
  animation: shake 0.4s infinite;
}

.logout-button {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
}

.logout-button img {
  transition: transform 0.3s;
}

.logout-button.animating img {
  animation: logout-animation 1.5s;
}

@keyframes logout-animation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

.menu-icon {
  display: none;
}
.navsection-items {
  background-color: #488efe;
}
.selected-navsection-items {
  background-color: white;
  color: #488efe;
  padding: 10px;
  border-radius: 10px;
}

.menu-inside {
  display: flex;
  flex-direction: column;
}

.menu-items .menu-items-container.selected {
  background-color: #fff;
  color: #488efe;
  border: 2px solid #488efe;
  border-radius: 5px;
  padding: 2px;
  font-size: medium;
  font-weight: 400;
  text-decoration: none;
}

@keyframes your-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 821px) and (max-width: 1023px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 200;
    justify-content: space-between;
    align-content: center;
    background-color: #488efe;
    padding: 5px;
  }
  .menu-icon {
    display: block;
  }
  .mob-profile {
    display: none;
  }
  .mob-content {
    display: none;
  }
  .mob-logout {
    display: none;
  }
  .cancel-btn {
    display: flex;
    justify-content: flex-end;
  }
  .hamburger-menu {
    position: relative;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .hamburger-icon {
    position: absolute;
    width: 30px;
    height: 20px;
    z-index: -2;
  }

  .menu-items {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    list-style: none;
    background-color: #fff;
    border: 1px solid #000;
    z-index: -1;
    height: 95vh;
    width: 200px;
    padding: 5px;
    border-radius: 3px;
  }

  .hamburger-menu.open .menu-items {
    display: block;
  }
  .menu-items-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .menu-inside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85vh;
  }

  .logo {
    position: relative;
    z-index: -12;
    display: flex;
    height: 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 820px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    z-index: 200;
    justify-content: space-between;
    align-content: center;
    background-color: #488efe;
    padding: 5px;
  }

  .menu-icon {
    display: block;
  }
  .mob-profile {
    display: none;
  }
  .mob-content {
    display: none;
  }
  .mob-logout {
    display: none;
  }
  .cancel-btn {
    display: flex;
    justify-content: flex-end;
  }

  .hamburger-menu {
    position: relative;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .hamburger-icon {
    position: absolute;
    width: 30px;
    height: 20px;
    z-index: -2;
  }
  .logo {
    position: relative;
    z-index: -12;
    display: flex;
    height: 50px;
  }

  .menu-inside {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 85vh;
  }
  .menu-items {
    position: absolute;
    top: 0;
    /* right: -28px; */
    right: -34px;
    display: none;
    list-style: none;
    background-color: #fff;
    border: 1px solid #000;
    z-index: -1;
    width: 200px;
    padding: 5px;
    border-radius: 3px;
    height: 95vh;
  }

  .hamburger-menu.open .menu-items {
    display: block;
  }
  .menu-items-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
