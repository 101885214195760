.forgetpage-Component {
  padding: 20px 10px;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}

.forget-pass-img {
  width: 550px;
  height: 550px;
}

.forgetpage-box {
  background-color: rgb(166, 210, 241);
  border-radius: 5px;
  height: 30vh;
  display: flex;
}

.otp-button {
  margin-top: 5px;
  height: 2rem;
  width: 9rem;
  align-items: center;
  justify-content: center;
  font-size: larger;
  font-weight: bold;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
.forgetpage-Component {
  padding: 20px 10px;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}

.forget-pass-img {
  width: 450px;
  
}
.forgetpage-box {
  background-color: rgb(166, 210, 241);
  border-radius: 5px;
  height: 20vh;
  display: flex;
}

}

@media screen and (min-width: 320px) and (max-width: 769px) {

  .forgetpage-Component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  

}
}