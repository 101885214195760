
.mainpage-Component {
  padding: 20px 10px;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}
.mainpage-image {
  width: 450px;
  height: 450px;
}
.mainpage-box {
  background-color: white;
  border-radius: 7px;
  height: 550px;
  display: flex;
}
.SelectedBtn{
  background-color: aliceblue;
  color: #488efe !important;
  width: 200px;
  border-radius: 5px;
}
.mainpage-smallbox {
  width: 450px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #488efe;
}

.Logo {
  width: 450px;
  height: 130px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.mainpage-signup {
  width: 200px;
  font-weight: bold;
  font-size: larger;
}
.mainpage-signup:hover {
  background-color: aliceblue;
  color: #488efe !important;
  width: 200px;
  border-radius: 5px;
}

.mainpage-login {
  width: 200px;
  font-weight: bold;
  font-size: larger;
}
.mainpage-login:hover {
  background-color: aliceblue;
  color: #488efe !important;
  width: 200px;
  border-radius: 5px;
}

.mainpage-text {
  margin-top: 8px;
  font-size: larger;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Location-box {
  background-color: black;
  border-radius: 5px;
  height: 30px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .mainpage-Component {
    background-image: url("/src/Assets/Images/background.svg");
    background-size: cover;
    width: auto;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .mainpage-image {
    display: none;
  }
  .mainpage-box {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 7px;
    width: 500px;
    height: 750px;
  }
  .mainpage-smallbox {
    width: 500px;
    height: 50px;
    display: flex;
    background-color: rgba(51, 83, 167, 1);
  }
  .logo-mainbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Logo {
    width: 340px;
    height: 150px;
  }

  .mainpage-text {
    font-size: larger;
    font-weight: bold;
    justify-content: center;
  }
  .mainpage-signup {
    font-weight: bold;
    font-size: x-large;
  }

  .mainpage-signup:hover {
    background-color: aliceblue;
    color: #488efe !important;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainpage-login {
    font-weight: bold;
    font-size: x-large;
  }
  .mainpage-login:hover {
    background-color: aliceblue;

    color: #488efe !important;
    width: 300px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 320px) and (max-width: 769px) {
  .mainpage-Component {
    background-image: url("/src/Assets/Images/background.svg");
    background-size: cover;
    width: auto;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .mainpage-image {
    display: none;
  }

  .mainpage-box {
    /* background-color: white; */
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 7px;
    width: 300px;
    height: 450px;
    opacity: inherit;
    /* align-items: self-start; */
    /* padding-bottom: 5px; */
  }
  .mainpage-signup {
    font-weight: bold;
    font-size: large;
  }

  .mainpage-signup:hover {
    background-color: aliceblue;
    color: #488efe !important;
    width: 300px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainpage-login {
    font-weight: bold;
    font-size: large;
  }
  .mainpage-login:hover {
    background-color: aliceblue;

    color: #488efe !important;
    width: 300px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Logo {
    width: 240px;
    height: 90px;
  }
  .logo-mainbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainpage-smallbox {
    width: 300px;
    height: 40px;
    display: flex;
    background-color: rgba(51, 83, 167, 1);
  }

  .mainpage-text {
    font-size: large;
    font-weight: bold;
    justify-content: center;
  }
}
