.Footer-main {
    font-size: larger;
    justify-content: space-around;
    font-weight: bold;
    color: mediumblue;
    
    
  }

  .Footer-Links {
    font-size: medium;
    align-items: flex-start;
    font-weight: normal;
    color: black;
    
    
  }

  .Footer-Poweredby {
    font-size: large;
    justify-content: flex-end;
    font-weight: bold;
    color: mediumblue;
    
    
  }

  .Footer-icons {
    height: 40px;
    justify-content: flex-start;
    gap: 10px;
     
   }

  @media screen and (min-width: 320px) and (max-width: 769px) {

    .Footer-main {
        font-size: x-small;
        justify-content: space-around;
        font-weight: bold;
        color: mediumblue;
        
        
      }

      .Footer-Links {
        font-size: xx-small;
        align-items: flex-start;
        font-weight: normal;
        color: black;
        
        
      }

      .Footer-Poweredby {
        font-size: small;
        justify-content: flex-end;
        font-weight: bold;
        color: mediumblue;
        
        
      }

      .Footer-Logo {
       height: 20px;
        
      }

      .Footer-icons {
        height: 15px;
        justify-content: flex-start;
        gap: 3px;
         
       }

    }