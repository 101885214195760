.Newsfeed {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .Newsbackground {
    height: auto;
    width: 80%;
    margin-top: 10px;
    background-color: aliceblue;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .Newscontent {
    display: flex;
    flex-direction: row;
    gap: 20px;

  }

  