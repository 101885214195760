.Box {
  display: flex;
  height: 40px;
  width: screen;
  font-size: larger;
  font-weight: bold;
  justify-content: space-around;
  align-items: center;
  background-color: #aec6ed;
  cursor: pointer;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 100;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Box {
    height: 5%;
    top: 65px;
  }
}
@media (max-width: 767px) {
  .Box {
    top: 65px;
    height: 50px;
  }
}
