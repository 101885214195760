.Profilepage{
  width: 100%;
}
.Leftbox {
  height: 700px;
  width: 30%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.Button {
  display: flex;
  height: 40px;
  width: 150px;
  font-size: larger;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}


.Uploads {
  width: 90%;
  cursor: pointer;
}

.highlighted {
  color: rgb(14, 63, 242);
  font-weight: bold;
}

.news-image.selected {
  filter: brightness(1.2);
}
.profile-post-content {
  display: block;
  padding: 10px;

  width: 90%;
}


@media screen and (min-width: 769px) and (max-width: 1023px) {
  .Leftbox {
    height: 100vh !important;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Profilepage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-post-content {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 820px) {
  .Leftbox {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 90vh;
  }

  .Profilepage {
    display: flex;
    display: flex;
 
    align-items: center;
    justify-content: center;
  }
  .profile-post-content {
    display: none;
  }
}
