.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}

.search-icon {
  display: flex;
  margin-top: 100px;
  gap: 5px;
  flex-direction: row;
}

.search {
  display: flex;
  width: 400px;
  height: 40px;
  background-color: #cddcf5;
}

.directory-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.box-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
  gap: 20px;
}

.wholebox {
  display: flex;
  height: 350px;
  width: 350px;
  justify-content: Center;
  align-items: center;
  background-color: #aec6ed;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (min-width: 320px) and (max-width: 769px) {
  .wholebox {
    display: flex;
    height: 350px;
    width: 300px;
    justify-content: Center;
    align-items: center;
    background-color: #aec6ed;
    cursor: pointer;
    border-radius: 10px;
  }

  .search {
    display: flex;
    width: 250px;
    height: 40px;
    background-color: #cddcf5;
  }

  .image-website {
    display: flex;
    width: 20px;
  }
}
