.aadhaar {
  color: #3d5ad0;
  font-size: 25px;
  font-weight:bold ;
}

.bg-btn{
  background-color: #1653F2; 
  cursor: pointer;
  color: #fff;
}
.otp-btn {
  border: 1px solid #fff;
  height: 5vh;
  width: 15vh;
  background-color: #4CAF50;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.form-container{
  border-radius: 10px;
  background-color: white;
  border:solid 2px #6C6CFD;
  width: 100vh;
  height: 60vh;
  align-items: center;
  justify-content: center;
}
.Aadharinput {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  
  .form-container{
    width: 500px;
    height: 35vh;
    }

    .Aadharinput {
      display: flex;
      flex-direction: row;
      
    }
    

}


@media only screen and (max-width:768px) {
  .form-container{
  width: 300px;
  height: 45vh;
  }

  .form-control {
    display: flex;
    font-size: smaller;
  }

  .aadhaar {
    color: #3d5ad0;
    font-size: 20px;
    font-weight:bold ;
  }

  .Aadharinput {
    display: flex;
    flex-direction: column;
  }
  
  
  
}