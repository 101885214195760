.community {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 100px;
  font-weight: bolder;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .community {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    font-size: 100px;
    font-weight: bolder;
  }
}

@media screen and (min-width: 320px) and (max-width: 769px) {
  .community {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 35px;
    font-weight: bolder;
  }
}
