.input-box {
  border-width: 1px;
  border-color: black;
  border-top: 0;
  border-left: 0;
  border-right: 0;
 
}

.Login-component {
    margin-top: 20px;
    margin-bottom: 10px;
}

.Login-button {
    margin-top: 5px;
    height: 30px;
    width: 100px;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: bold

}

.Forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  font-weight: bold;
}


@media screen and (min-width: 320px) and (max-width: 769px) {
  .input-box {
    border-width: 1px;
    border-color: black;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    font-size: x-small;
      
  }

  .Login-component {
    margin-top: 10px;
    margin-bottom: 5px;
}

.Login-button {
    margin-top: 5px;
    height: 25px;
    width: 60px;
    align-items: center;
    justify-content: center;
    font-size: smaller;
    font-weight: bold
    
}

.alert-text {
  font-size: smaller;
 
}

.Visibility {
  width: 15px;
 
}

.Forgot-password {
  display: flex;
  justify-content: flex-end;
  font-size: small;


}

}
